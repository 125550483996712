import React from "react";
import firebase from "firebase";

import logo from "./MWMusicIcon.png";

export default class Admin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      songs: [],
    };
  }

  componentDidMount = () => {
    this.pwdPrompting()
    this.readFromDB();
  };

  pwdPrompting = () => {
    let pwd = ''
    while (pwd !== process.env.REACT_APP_BACKEND_PASSWORD) {
      pwd = window.prompt("Enter the password to enter the page:")
    }
  }

  uploadFile = () => {
    const storage = firebase.storage();
    const file = document.getElementById("files").files[0];
    const title = document.getElementById("title").value;
    if (!title || !file) {
      alert("Must input a title and a file");
      return;
    }
    const storageRef = storage.ref();
    const localRef = storageRef
      .child("uploads")
      .child(title)
      .child(file.name)
      .put(file);
    localRef.on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        console.log(error);
      },
      () => {
        // Uploaded completed successfully, now we can get the download URL
        localRef.snapshot.ref.getDownloadURL().then((downloadURL) => {
          this.writeToDB(downloadURL, title, file.name);
          alert("uploaded successfully");
        });
      }
    );
  };

  writeToDB = (url, name, filename) => {
    const dbRef = firebase.database().ref("Uploads").push();
    dbRef.set({
      url: url,
      title: name,
      uploaded: Math.floor(Date.now()),
      storage_url: `uploads/${name}/${filename}`,
    });
  };

  readFromDB = () => {
    const dbRef = firebase.database().ref("Uploads");
    dbRef.on("value", (snapshot) => {
      const data = snapshot.val();
      if (!data) {
        return
      }
      this.setState({
        songs: Object.keys(data).map((key) =>({
          key,
          ...data[key]
        })),
      });
      console.log(Object.keys(data).map((key) =>({
        key,
        ...data[key]
      })),)
    });
  };

  removeSong = (storage_url, key) => {
    if (window.confirm("Are you sure you want to remove this song?")) {
      const storageRef = firebase.storage().ref();
      const localRef = storageRef.child(storage_url);
      localRef
        .delete()
        .then(() => {
          alert(`${storage_url} deleted successfully!`);
        })
        .catch((error) => {
          alert(`Error deleting ${storage_url}. ${error.message}`);
        });
      firebase.database().ref(`Uploads/${key}`).remove();
    }
  };

  render() {
    return (
      <>
        <img
          onClick = {() => window.location.replace('/')}
          style={{
            width: 120,
            margin: 20,
            cursor: 'pointer'
          }}
          src={logo}
          alt="img"
        />
        <h1 style={{ margin: 20 }}>Music File Upload Manager</h1>
        <form id="contactForm" style={{ margin: 20 }}>
          <h2>New Song Upload</h2>
          <br />
          <input type="text" id="title" placeholder="Song title" /> <br />
          <br />
          <input type="file" name="files[]" id="files" accept="audio/mpeg" />
          <br />
          <br />
          <input type="hidden" name="url" id="urlx" />
          <button type="button" onClick={() => this.uploadFile()}>
            Upload File
          </button>
          <br />
          <br />
        </form>
        <hr />
        <h2 style={{ margin: 20 }}>Current Songs</h2>
        {this.state.songs.map((song) => (
          <div
            key={song.uploaded}
            style={{
              flexDirection: "row",
              display: "flex",
              margin: 20,
              flexWrap: "wrap",
            }}
          >
            <div style={{ margin: 20 }}>{song.title}</div>
            <div style={{ margin: 20 }}>
              Uploaded: {new Date(song.uploaded).toLocaleDateString()}
            </div>
            <audio controls>
              <source src={song.url} type="audio/mpeg" />
              Your browser does not support the <code>audio</code> element.
            </audio>
            <button
              style={{ margin: 20 }}
              onClick={() => {
                this.removeSong(song.storage_url, song.key);
              }}
            >
              Remove Song
            </button>
          </div>
        ))}
      </>
    );
  }
}
