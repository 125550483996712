import React from "react";
import firebase from "firebase";
import ReactJkMusicPlayer from './react-jinke-music-player'
import './react-jinke-music-player/assets/index.css'

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      songs: []
    }
  }

  componentDidMount = () => {
    this.readFromDB()
    const playlistTitle = document.getElementsByClassName("audio-lists-panel-header-title")
    for (let i = 0; i < playlistTitle.length; i++) {
      playlistTitle[i].children[0].innerHTML = "Songs:"
    }
  }

  readFromDB = () => {
    const dbRef = firebase.database().ref('Uploads')
    dbRef.on('value', (snapshot) => {
      const data = snapshot.val();
      this.setState({
        songs: Object.values(data)
      })
    })
  };

  render() {
      return (
        <>
            <ReactJkMusicPlayer 
              defaultVolume={1}
              getAudioInstance={(instance) => {
                this.audioInstance = instance
              }}
              onAudioVolumeChange={(vol) => console.log(vol)}
              remove={false}
              toggleMode={false}
              mobileMediaQuery="(max-width: 2000px)"
              mode="full"
              audioLists={this.state.songs.map((song) => ({
                name: song.title,
                musicSrc: song.url,
                cover: 'https://firebasestorage.googleapis.com/v0/b/midweekmusic-c3161.appspot.com/o/uploads%2FMWMusicIcon.png?alt=media&token=8cab5c5e-31ed-4d22-a49b-f7faf38d349f',
                singer: 'Mid Week Music'
              }))}
              showMediaSession
             />,
        </>
      )
  }
}
