import "./App.css";
import firebase from "firebase";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Admin from './Admin'
import Home from './Home'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  databaseUrl: process.env.REACT_APP_FIREBASE_DATABASE_URL
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

function App() {
  return (
    <Router>
       <Route path="/" exact component={Home} />
       <Route path="/admin" exact component={Admin} />
    </Router>
  );
}

export default App;
